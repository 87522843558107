import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import css from './DateTimePills.module.css';
import moment from 'moment';

const DateTimePills = ({ days }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowMore = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Filter out days before today
  const filteredDays = days.filter(day => moment(day.date).isSameOrAfter(moment(), 'day'));

  return (
    <div className={css.pillsContainer}>
      <div className={css.titleWrapper}>
        <label>Schedule</label>
      </div>

      <div className={css.selectedDaysWrapper}>
        {filteredDays.slice(0, 5).map((day, index) => (
          <div key={index} className={css.pill}>
            <span>
              {moment(day.date).format('MMM D, YYYY')}
              {day.startTime && day.endTime && (
                <span className={css.timeRange}>{` (${day.startTime} - ${day.endTime})`}</span>
              )}
            </span>
          </div>
        ))}
        {filteredDays.length > 5 && (
          <button className={`${css.pill} ${css.showMoreButton}`} onClick={handleShowMore}>
            Show More
          </button>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        usePortal
        onManageDisableScrolling={() => {}}
        id="dateTimePillsModal"
      >
        <div className={css.modalContent}>
          <label>All Days</label>
          <div className={css.selectedDaysWrapper}>
            {filteredDays.map((day, index) => (
              <div key={index} className={css.modalPill}>
                <span>
                  {moment(day.date).format('MMM D, YYYY')}
                  {day.startTime && day.endTime && (
                    <span className={css.timeRange}>{` (${day.startTime} - ${day.endTime})`}</span>
                  )}
                </span>
              </div>
            ))}
          </div>
          <div className={css.modalActions}>
            <button className={css.closeModalButton} onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DateTimePills;
